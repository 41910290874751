import { defineStyleConfig } from '@chakra-ui/react';
export const NavItem = defineStyleConfig({
  // The styles all Cards have in common
  baseStyle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    px: 4,
    py: 2,
    borderRadius: '20px',
    _hover: {
      color: 'accent.darkest',
      bg: 'accent.lightest',
    },
  },
  variants: {
    default: {
      color: 'action.darkest',
      fontFamily: 'Kanit',
      fontSize: 'md',
      _hover: {
        bg: 'accent.lightest',
      },
    },
    selected: {
      background: 'accent.default',
    },
  },
  defaultProps: {
    variant: 'default',
  },
});
