export const switchTheme = {
  baseStyle: {
    container: {},
    thumb: {},
    track: {
      bg: 'action.lighter',
      _checked: {
        bg: 'action.default',
      },
    },
  },
};
