import { inputOutlineVariantStyles } from './shared/formStyles';
export const textareaTheme = {
  baseStyle: {},

  variants: {
    outline: inputOutlineVariantStyles,
  },
  defaultProps: {
    variant: 'outline',
  },
};
