export const colors = {
  accent: {
    lightest: '#EDE7F6',
    lighter: '#D1C4E9',
    light: '#9575CD',
    default: '#673AB7',
    dark: '#542CA7',
    darker: '#4A259F',
    darkest: '#391890',
  },
  action: {
    lightest: '#E8F2F0',
    lighter: '#9FCAC2',
    light: '#7EBAAF',
    default: '#3F9585',
    dark: '#0D4A5B',
    darkest: '#1C3D41',
  },
  background: {
    dark: '#10113B',
    light: '#F4F4F9',
  },
  text: {
    primary: '#333333',
    secondary: '#777',
    tertiary: '#aaa',
    white: '#fff',
    link: 'blue',
  },
  tags: {
    privacy: '#6E64B0',
    gameType: '#AA1155',
    gameLimit: '#326771',
  },
};
