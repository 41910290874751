export const radioTheme = {
  baseStyle: {
    control: {
      borderRadius: '12px',
      borderColor: 'action.default',
      '&[data-checked]': {
        bg: 'action.default',
        borderColor: 'action.default',
        '&:hover': {
          bg: 'action.default',
          borderColor: 'action.default',
        },
      },
    },
    container: {
      pr: '1rem',
    },
    label: {
      ml: '0.25rem',
    },
  },
};
