import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

// Web app's Firebase configuration, with project keys
const firebaseConfig = {
  apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  authDomain: "pokercitygrid.firebaseapp.com",
  databaseURL: "https://pokercitygrid-default-rtdb.firebaseio.com",
  projectId: "pokercitygrid",
  storageBucket: "pokercitygrid.appspot.com",
  messagingSenderId: "283146481305",
  appId: "1:283146481305:web:8151300804073cdc18d208",
  measurementId: "G-MPVRE775FN"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore and get a reference to the service
export const db = getFirestore(app);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);

// Initialize Firebase Storage and get a reference to the service
export const storage = getStorage(app);