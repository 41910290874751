import { extendTheme } from '@chakra-ui/react';
import { global } from './base/global';
import { Button } from './components/Button';
import { inputTheme } from './components/Input';
import { colors } from './base/colors';
import { NavItem } from './components/NavItem';
import { SIZES } from './base/sizes';
import { space } from './base/space';
import { fonts } from './base/fonts';
import { textStyles } from './base/typography';
import { headingTheme } from './components/Heading';
import { textareaTheme } from './components/Textarea';
import { stepperTheme } from './components/Stepper';
import { radioTheme } from './components/Radio';
import { selectTheme } from './components/Select';
import { numberInputTheme } from './components/NumberInput';
import { switchTheme } from './components/Switch';
import { tagTheme } from './components/Tag';
import { tabsTheme } from './components/Tabs';
const theme = {
  styles: { global },
  fonts,
  textStyles,
  colors,
  space,
  sizes: SIZES,
  components: {
    Button,
    NavItem,
    Heading: headingTheme,
    Input: inputTheme,
    NumberInput: numberInputTheme,
    Textarea: textareaTheme,
    Stepper: stepperTheme,
    Radio: radioTheme,
    Select: selectTheme,
    Switch: switchTheme,
    Tag: tagTheme,
    Tabs: tabsTheme,
  },
};

export default extendTheme(theme);
