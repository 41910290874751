export const textStyles = {
    h1: {
        fontSize: ['2.5rem'],
        fontWeight: 'bold',
        lineHeight: '110%',
        letterSpacing: '-2%',
    },
    h4: {
        fontSize: ['1.5rem'],
        fontWeight: 'normal'
    }
}