import { inputOutlineVariantStyles } from './shared/formStyles';
export const numberInputTheme = {
  baseStyle: {},

  variants: {
    outline: {
      root: { width: 'full' },
      field: { ...inputOutlineVariantStyles },
    },
  },
  defaultProps: {
    variant: 'outline',
  },
};
