import { CircularProgress } from "@chakra-ui/react";

export default function PinProgressCircle() {
  return (
    <CircularProgress
      isIndeterminate
      size={["60px", "100px", "100px", "120px"]}
      thickness="4px"
      color="blackAlpha.800"
      trackColor="gray.100"
    />
  );
}