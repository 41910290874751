import { GoogleAuthProvider, onAuthStateChanged, signOut, signInWithPopup, User, signInWithEmailAndPassword } from "firebase/auth";
import React, { useContext, createContext, useEffect, useState, ReactNode } from 'react';
import { auth } from "./firebase";

interface AuthContextProps {
  googleSignIn: () => void;
  emailSignIn: (email: string, password: string) => void;
  logOut: () => void;
  user: User | null;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const AuthContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);

  const googleSignIn = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider).then(() => {
      console.log("User signed in")
    }).catch((error) => {
      console.log(error)
    });
  }

  const emailSignIn = (email: string, password: string) => {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        console.log(user)
        console.log("User signed in")
      })
      .catch((error) => {
        console.log(error)
      });
  }

  const logOut = () => {
    signOut(auth).then(() => {
      console.log("User signed out")
    }).catch((error) => {
      console.log(error)
    });
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      console.log('User auth state changed');
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <AuthContext.Provider value={{ googleSignIn, emailSignIn, logOut, user }}>
      {children}
    </AuthContext.Provider>
  );
};

export const UserAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('UserAuth must be used within a AuthContextProvider')
  }
  return context;
};