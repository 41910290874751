import React from 'react';
import { AuthContextProvider } from './AuthContext.tsx';
import { ChakraProvider } from '@chakra-ui/react';
import theme from './assets/themes/theme.ts';
import { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import Protected from './components/protected.js';
import Fallback from './components/Fallback.js';
import { LoadScript } from '@react-google-maps/api';

const LandingPage = lazy(
  () => import('./new-views/LandingPage/LandingPage.tsx')
);
const ComingSoonPage = lazy(
  () => import('./new-views/ComingSoonPage/ComingSoonPage.tsx')
);

const DashboardPageDeprecate = lazy(
  () => import('./views/DashboardPage/DashboardPage.js')
);

const DashboardPage = lazy(() => import('./new-views/Dashboard/Dashboard.tsx'));
const CreateEvent = lazy(
  () => import('./new-views/CreateEventPage/CreateEventPage.tsx')
);

const EventView = lazy(() => import('./new-views/EventView/EventView.tsx'));

const MyEventsPageDeprecate = lazy(
  () => import('./views/my-events/MyEvents.js')
);

const EventDeprecate = lazy(() => import('./views/event/Event.js'));

const EventFormStateDeprecate = lazy(
  () => import('./views/create-event/EventFormState.js')
);

const PrivacyPolicy = lazy(
  () => import('./views/PrivacyPolicy/PrivacyPolicy.js')
);

const PageNotFound = lazy(() => import('./views/PageNotFound/PageNotFound.js'));
const libraries = ['places'] as any[];

// Add async to google maps api key to force load async
const googleMapsApiKey = `${process.env.REACT_APP_GOOGLE_API_KEY}&loading=async`;
function App() {
  return (
    <AuthContextProvider>
      <ChakraProvider theme={theme}>
        <LoadScript googleMapsApiKey={googleMapsApiKey} libraries={libraries}>
          <Suspense fallback={<Fallback />}>
            {/* this is the loading screen */}
            <Routes>
              <Route path='*' element={<PageNotFound />} />
              {/* TODO redirect to dashboard if logged in */}
              <Route path='/' element={<ComingSoonPage />} />
              {/* Uncomment after coming soon */}
              {/* 
              <Route path='/' element={<LandingPage />} />

              <Route
                path='/dashboard'
                element={
                  <Protected>
                    {' '}
                    <DashboardPage />{' '}
                  </Protected>
                }
              />
              <Route
                path='/edit-event/:eventId'
                element={
                  <Protected>
                    <CreateEvent />
                  </Protected>
                }
              />
              <Route
                path='/create-event/'
                element={
                  <Protected>
                    <CreateEvent />
                  </Protected>
                }
              />

              <Route
                path='/event/:eventId'
                element={
                  <Protected>
                    <EventView />
                  </Protected>
                }
              /> */}
              {/* DEPRECATED ROUTES */}
              {/* <Route
                path='/deprecate/create-event'
                element={
                  <Protected>
                    <EventFormStateDeprecate />
                  </Protected>
                }
              />
              <Route
                path='/deprecate/dashboard'
                element={<DashboardPageDeprecate />}
              />
              <Route
                path='deprecate/my-events'
                element={
                  <Protected>
                    <MyEventsPageDeprecate />
                  </Protected>
                }
              />
              <Route
                path='/deprecate/event/:eventId'
                element={<EventDeprecate />}
              /> */}
              {/* END DEPRECATED ROUTES */}
              <Route path='privacy-policy' element={<PrivacyPolicy />} />
              {/* delete when email sign in is implemented 
              <Route path='login' element={<LoginPage />} />*/}
            </Routes>
          </Suspense>
        </LoadScript>
      </ChakraProvider>
    </AuthContextProvider>
  );
}
export default App;
