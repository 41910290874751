export const stepperTheme = {
  baseStyle: {
    indicator: {
      color: 'action.default',
      bgColor: 'action.lighter',
      borderColor: 'action.default',

      '&[data-status=complete]': {
        bgColor: 'action.default',
        borderColor: 'action.dark',
        borderWidth: '3px',
      },

      '&[data-status=active]': {
        bgColor: 'transparent',
        borderColor: 'action.default',
        borderWidth: '3px',
      },

      '&[data-status=incomplete]': {
        bgColor: 'transparent',
        borderColor: 'action.lighter',
        borderWidth: '3px',
      },
    },
    separator: {
      bgColor: 'action.default',
      color: 'action.default',
      '&[data-status=complete]': {
        bgColor: 'action.default',
      },
      '&[data-status=active]': {
        bgColor: 'action.default',
        borderColor: 'action.default',
      },
      '&[data-status=incomplete]': {
        bgColor: 'action.lighter',
        borderColor: 'action.lighter',
      },
    },

    icon: {
      borderColor: 'brand.blue',
      bgColor: 'transparent',
    },
  },
  sizes: {
    lg: {
      // select the title part
      title: {
        // change the font size to lg
        fontSize: 'lg',
      },
    },
  },
};
