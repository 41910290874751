export const _SIZES = {
  topNavHeight: '75px',
  pageMaxWidth: '1280px',
};

export const SIZES = {
  topNavHeight: _SIZES.topNavHeight,
  pageMaxWidth: _SIZES.pageMaxWidth,
  contentHeight: `calc(100vh - ${_SIZES.topNavHeight})`,
};
