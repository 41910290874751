export const space = {
  mainMarginLg: '3rem',
  mainMarginSm: '1rem',
};

export const PX_MAIN = [4, 6, 6, 6, 0];
export const z = {
  topNav: 2,
  footer: 2,
};

export const formSpace = {
  formSectionGap: ['20'],
  formColumnGap: ['1rem'],
  formSubColumnGap: ['.5rem'],
  subSectionPadding: [6],
  labelGap: ['.5rem'],
  infoTooltipGap: ['.25rem'],
};
