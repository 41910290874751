import { defineStyle } from '@chakra-ui/react';

export const headingTheme = defineStyle({
  fontFamily: 'kanit',
  variants: {
    heading1: {
      fontSize: ['2rem', '2rem', '2.5rem'],
      textTransform: 'uppercase',
      letterSpacing: 'widest',
      fontWeight: 'normal',
    },
    heading2: {
      fontSize: ['1.5rem', '1.75rem', '2rem'],
      fontWeight: '500',
      letterSpacing: 'wide',
    },
    heading3: {
      ontSize: ['1.25rem', '1.25rem', '1.5rem'],
      letterSpacing: 'wide',
      fontWeight: '300',
    },
    heading4: {
      fontSize: ['1rem', '1rem', '1.25rem'],
      letterSpacing: 'wide',
      fontWeight: '400',
    },
  },
});
