export const tagTheme = {
  baseStyle: {
    // textTransform: 'uppercase',
  },
  variants: {
    privacy: {
      container: {
        bg: 'tags.privacy',
        color: 'white',
        textTransform: 'uppercase',
      },
    },
    gameType: {
      container: {
        bg: 'tags.gameType',
        color: 'white',
        textTransform: 'uppercase',
      },
    },
    gameLimit: {
      container: {
        bg: 'tags.gameLimit',
        color: 'white',
        textTransform: 'uppercase',
      },
    },
  },
};
