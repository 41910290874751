import { Center, Box } from '@chakra-ui/react';
import PinProgressCircle from "./PinProgressCircle.js";

const fallbackStyle = {
  position: "fixed",
  top: "0",
  left: "0",
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent black background for the blur effect
  zIndex: "9999", // Ensure it's on top of everything
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backdropFilter: "blur(5px)", // CSS blur effect
};

export default function Fallback() {
  return (
    <Box style={fallbackStyle}>
      <Center>
        <PinProgressCircle />
      </Center>
    </Box>
  );
}