export const inputOutlineVariantStyles = {
  border: '1px solid',
  bg: 'white',
  _hover: {
    borderColor: 'action.lighter',
  },
  _focus: {
    borderColor: 'action.default',
    boxShadow: 'none',
  },
};

export const addonOutlineVariantStyles = {
  bg: 'white',
};
