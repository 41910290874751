import { defineStyleConfig } from '@chakra-ui/react';

const disabledStyles = {
  primary: {
    bg: 'action.default',
    opacity: 0.4,
    cursor: 'default',
  },
};
export const Button = defineStyleConfig({
  baseStyle: {
    fontWeight: 'medium',
    borderRadius: '20px',
    letterSpacing: 'wider',
    textTransform: 'uppercase',
  },
  sizes: {
    sm: {
      fontSize: 'sm',
    },
    md: {
      fontSize: 'md',
    },
  },
  variants: {
    primary: {
      color: 'white',
      bg: 'action.default',
      textTransform: 'uppercase',
      fontWeight: '500',
      _hover: {
        bg: 'action.light',
        _disabled: {
          ...disabledStyles.primary,
        },
      },
      _disabled: {
        ...disabledStyles.primary,
        _hover: {
          ...disabledStyles.primary,
        },
      },
    },
    secondary: {
      color: 'action.default',
      bg: 'white',
      border: '1px solid',
      borderColor: 'action.default',
      _hover: {
        bg: 'action.lightest',
      },
    },
    tertiary: {
      color: 'action.default',
      bg: 'white',
      _hover: {
        bg: 'action.lightest',
      },
    },
    link: {
      color: 'text.link',
      bg: 'transparent',
      _hover: {
        textDecoration: 'underline',
      },
    },
    footerLink: {
      color: 'text.primary',
      bg: 'transparent',
      textTransform: 'none',
      _hover: {
        textDecoration: 'underline',
        color: 'text.link',
      },
    },
    cta: {
      color: 'white',
      bg: 'action.default',

      fontWeight: 'semibold',
      letterSpacing: '0.1rem',
      px: '100px',
      py: 4,
      _hover: {
        bg: 'action.light',
      },
    },
  },
  // The default size and variant values
  defaultProps: {
    size: 'md',
    variant: 'primary',
  },
});
