import { background, defineStyleConfig } from '@chakra-ui/react';

export const tabsTheme = defineStyleConfig({
  baseStyle: {
    tab: {
      color: 'text.primary',
      fontWeight: 'medium',
      _selected: {
        color: 'accent.default',
        backgroundColor: 'accent.lightest',
        fontWeight: 'medium',
      },
      _hover: {
        color: 'accent.default',
      },
    },
  },
});
