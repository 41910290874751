import { inputOutlineVariantStyles } from './shared/formStyles';
export const selectTheme = {
  baseStyle: {},
  variants: {
    outline: {
      field: inputOutlineVariantStyles,
    },
  },
  defaultProps: {
    variant: 'outline',
  },
};
