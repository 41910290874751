import { inputOutlineVariantStyles } from './shared/formStyles';

export const inputTheme = {
  baseStyle: {},

  variants: {
    outline: {
      field: inputOutlineVariantStyles,
      addon: {
        border: 'none',
        background: 'gray.100',
        color: 'gray.900',
      },
    },
  },
  defaultProps: {
    variant: 'outline',
  },
};
